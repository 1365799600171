<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      You are shown a bunch of lines in the MagicGraph. The blue lines are parallel to each other. The red lines are perpendicular to each other. The value of
      angle &alpha; is given. <br>
      Find the value of angle &beta;.
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Properties of Parallel Lines </h5>
    </div>
    <p class="big mt-2">
      Use the MagicGraph below to participate in the exercise and record your answers. Tap on <i class="shuffler ma-1" /> button to move over to the next question.
      Tap on <i class="tester ma-1" /> button to check your answer.
      Tap on <i class="undoer ma-1" /> button to start over from the begining.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> MagicGraph Navigation Buttons </h5>
    <p class="big mt-2">
      <i class="redoer ma-1" /> &mdash; is the 'Next' button. Tap on this button to move over to the next question. <br>
      <i class="tester ma-1" /> &mdash; is the 'Check Your Answer' button. Tap on this button to check your answer.<br>
      <i class="logor ma-1" />&mdash;  is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> &mdash; is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Markings & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status. <br>
      <i class="righter ma-1" /> &mdash; the right checkmark indicates that your attempted response was correct. <br>
      <i class="wrong ma-1" /> &mdash; this crossmark indicates that your attempted response was incorrect. <br>
      <i class="missed ma-1" /> &mdash; this exclaimation mark indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ParallelLines',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Parallel Lines';
    this.$store.commit('navigation/changeTitle', title);
  /* this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Parallel Lines',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about parallel lines'}
                ]
        }
   },
}
</script>
