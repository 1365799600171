import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  drawIntersection,
  drawPerpP,
  drawPerp,
  placeUndo
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-7.5,7.5,-5,10);
makeResponsive(graph);
graph.options.layer['image']=12;
graph.options.layer['text']=2;
//graph.options.layer['line']=28;
graph.options.layer['point']=2;
graph.options.layer['sector']=13;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
//var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var yourPerf =writeHTMLText(graph, positionX, positionY+4, 'How well did I do?');
var scoreCard = writeHTMLText(graph, positionX, positionY+3, function(){return 'Right Answers ( &check; ) : '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY+2, function(){return 'Missed Questions ( ! ) : '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Wrong Answers ( x ) : '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
yourPerf.setAttribute({visible:false});
//
var chartimg =graph.create('chart', [[()=>0.1+yourScore,()=>0.1+yourWrong, ()=>0.1+yourMissed]], {chartStyle:'pie', visible:()=>k==1, center:[positionX, positionY-2.5], colorArray:['red','blue','green'],
labels:['Right', 'Wrong', 'Missed'], label:{display:'html',offset:[0, -10]}, strokeColor:'white', radius:2.5, strokeWidth:1});
//chartimg[0].setProperty({visible:false});
//
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const PtmList = [1/4, 1/2, 3/4, 6/5, 8/5];
var XList=[4, 4, 3, 5, 6];
var YList=[4, 3, 4, 2, 4];
//var Ptm = drawPoint(graph, PtmList[0], 8);
//Ptm.setAttribute({visible:false});
const answers = [['55^o', '35^o', '135^o', '45^o'], ['143.1^o', '123.1^o', '133.1^o', '113.1^o'],['116.9^o', '126.9^o', '136.9^o', '146.9^o'],['158.2^o', '148.2^o', '138.2^o', '128.2^0'], ['135^o', '123.1^o', '158.2^o', '146.3^o']];
const index_right_answer = [2,0,1,0,3];
/**/
/********************************************************************************************/
var Jt1 = drawPoint(graph, 4, 4);
Jt1.setAttribute({visible:false});
var St1 = drawPoint(graph, 0.0, 4.0);
St1.setAttribute({visible:false});
var St3 = drawPoint(graph, -10.0, 4.0);
St3.setAttribute({visible:false});
var Pt1 = drawPoint(graph, 0.0, 4.0);
Pt1.setAttribute({visible:false});
//Pt1.setAttribute({snapToGrid:true});
var Pt2 = drawPoint(graph, 4.0, 0.0);
Pt2.setAttribute({visible:false});
var Jt2 = drawPoint(graph, 0, 0);
Jt2.setAttribute({visible:false});
var PLn1 = drawLine(graph, St1, Jt1);
PLn1.setAttribute({strokeColor:'blue'});
setWidth(graph, PLn1, '4');
var PLn2 = drawLine(graph, Jt2, Pt2);
PLn2.setAttribute({strokeColor:'blue'});
setWidth(graph, PLn2, '4');
//Pt2.setAttribute({snapToGrid:true});
var Ln =drawLine(graph, Pt1, Pt2);
Ln.setAttribute({strokeColor:'red'});
setWidth(graph, Ln, 4);
var Ptint = drawIntersection(graph, Ln, PLn1);
setSize(graph, Ptint, 0);
//////////////////////////////////////////
var Pt = drawPoint(graph, .0, 0.);
setSize(graph, Pt, 0);
///////////////////////////////////////
var perp = drawPerpP(graph, Ln, Pt);
//perp.setAttribute({fillColor:'white', strokeColor:'black'});
setSize(graph, perp, 0);
//labelIt(graph, perp, 'D');
var Lp = drawLine(graph, Pt, perp);
Lp.setAttribute({strokeColor:'red'});
setWidth(graph, Lp, 4);
//drawPerp(graph, Ln, Pt)
//var ints = drawIntersection(graph, Ln, perp);
//ints.setAttribute({size:0});
var alpha =drawAngle(graph, Pt2, Pt, perp);
alpha.setAttribute({fillColor:'green'});
alpha.label.setAttribute({offset:[10, 10]});
var beta =drawAngle(graph, St3, Ptint, Pt2);
beta.label.setAttribute({offset:[-10, -10]});
beta.setAttribute({fillColor:'blue'});
var ang =drawAngle(graph, Pt2, perp, Pt);
ang.label.setAttribute({visible:false})
/***********************************************************************************/
/*************************************PRINT ANSWER ****************************************/
//print(graph, ()=> (0.5*(180-2*alpha.Value()*180/Math.PI)).toFixed(1))
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, function(){return 'Value of &alpha; is '+ (alpha.Value()*180/Math.PI).toFixed(1) + '^o. Then angle &beta; is &mdash;'});
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not attempt!');
note.setAttribute({visible:false});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
//test.setAttribute({visible:()=>i<=4});
/**/
//var prev = placeUndo(graph, 'left');
//hoverMe(graph, prev, 'Previous Question', 0, 0);
//prev.setAttribute({visible:()=>i>0});
//
var next = placeRedo(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
next.setAttribute({visible:()=>i<5});
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
var i=0; var k=0; var l=0; var vis=false;
///////////////////////////////////////////////////////////////
var ansList = ['55^o', '35^o', '135^o', '45^o'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, 'The blue lines are parallel to each other. <br> The red lines are perpendicular to each other. <br>The value of angle α is given. <br> Goal is to find the value of angle β.');
hint.setAttribute({anchorX:'middle', visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==PtmList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  yourPerf.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  k=1;
  //chartimg.updateVisibility(true);
  test.setAttribute({visible:false});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  comment.setAttribute({visible:false});
  alpha.setAttribute({visible:false});
  beta.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    yourPerf.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  //Ptm.moveTo([PtmList[i], 8], 200);
  Pt1.moveTo([0, YList[i]], 200);
  Pt2.moveTo([XList[i], 0], 200);
  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    yourPerf.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0; k=0; l=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    //chartimg[0].setAttribute({visible:false});
    //chartimg.updateVisibility(false);
    test.setAttribute({visible:true});
    ////////////////////////ALL CHANGES GO HERE/////////////
    Pt1.moveTo([0, YList[0]], 200);
    Pt2.moveTo([XList[0], 0], 200);
    ////////////////////////////////////////////////////////
    alpha.setAttribute({visible:true});
    beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'See problem statement', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
  l = toggle(l);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:l});
  yourPerf.setAttribute({visible:false});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
  comment.setAttribute({visible:!vis});
  alpha.setAttribute({visible:!vis});
  beta.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
});
/******************END OF FILE ***************************/
}
}
export default Boxes;
